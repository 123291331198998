import * as React from 'react';
import { CSS, styled, VariantProps } from 'styles';

type CSSProp = { css?: CSS };

const DEFAULT_TAG = 'div';

const StyledIconBox = styled(DEFAULT_TAG, {
  display: 'flex',
  width: 'fit-content',
  height: 'fit-content',
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'not-allowed',
  },
  '&:focus': {
    outlineColor: '#DCD4FF',
    outlineStyle: 'solid',
    outlineWidth: 'medium',
  },
  variants: {
    variant: {
      rounded: {
        borderRadius: 4,
      },
      circle: {
        borderRadius: 99999,
      },
    },
  },
  defaultVariants: {
    variant: 'rounded',
  },
});

type IconBoxVariants = VariantProps<typeof StyledIconBox>;

export type IIconBoxProps = CSSProp &
  IconBoxVariants &
  React.ButtonHTMLAttributes<HTMLDivElement>;

const IconBox = React.forwardRef<HTMLDivElement, IIconBoxProps>(
  (props, ref) => {
    return <StyledIconBox {...props} ref={ref} />;
  },
);

IconBox.displayName = 'IconBox';

export default IconBox;
