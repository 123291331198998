import { styled } from 'styles';

export const FloatingContainer = styled('a', {
  backgroundColor: '#25d366',
  width: 64,
  height: 64,
  position: 'fixed',
  bottom: 24,
  right: 24,
  borderRadius: 9999,
  zIndex: 10,
  boxShadow:
    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  cursor: 'pointer',
  transition: 'all 0.15s ease-in-out',
  '&:hover': {
    backgroundColor: '#03ff61',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
