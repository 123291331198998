import Head from 'next/head';

export default function WirajayaMedata() {
  return (
    <Head>
      <link rel="manifest" href="manifest.json" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="application-name" content="Wirajaya Lines" />
      <meta name="apple-mobile-web-app-title" content="Wirajaya Lines" />
      <meta name="theme-color" content="#104FA6" />
      <meta name="msapplication-navbutton-color" content="#104FA6" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta name="msapplication-starturl" content="/" />
    </Head>
  );
}
