import { PredefinedFirst } from 'common/helpers/helper.type';
import * as React from 'react';
import { styled, CSS, VariantProps } from 'styles';
import colors, { ColorVariantType } from 'styles/color';
import typography from 'styles/typography';

const DEFAULT_TAG = 'span';

const StyledText = styled(DEFAULT_TAG, {
  // Reset
  lineHeight: '1',
  margin: '0',
  fontWeight: '$regular',
  fontVariantNumeric: 'tabular-nums',
  display: 'block',
  color: '$defaultFont',

  variants: {
    align: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
      justify: {
        textAlign: 'justify',
      },
    },
    size: {
      xxs: {
        fontSize: '$xxs',
      },
      xs: {
        fontSize: '$xs',
      },
      sm: {
        fontSize: '$sm',
      },
      md: {
        fontSize: '$md',
      },
      lg: {
        fontSize: '$lg',
      },
      xl: {
        fontSize: '$xl',
      },
      xxl: {
        fontSize: '$xxl',
      },
    },
    variant: {
      ...typography,
      noVariant: {},
    },
    weight: {
      regular: {
        fontWeight: '$regular',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
  },
  defaultVariants: {
    variant: 'body',
  },
});

type TextProps = React.HTMLAttributes<HTMLSpanElement>;
type TextCSSProp = { css?: CSS };
type TextVariants = VariantProps<typeof StyledText>;
export type ITextProps = TextProps &
  TextCSSProp &
  TextVariants & {
    color?: PredefinedFirst<ColorVariantType>;
  };

const Text = React.forwardRef<HTMLSpanElement, ITextProps>((props, ref) => {
  const { color, style, ...rest } = props;

  return (
    <StyledText
      {...rest}
      style={{
        ...style,
        color: colors[color as ColorVariantType] || color,
      }}
      ref={ref}
    />
  );
});

Text.displayName = 'Text';

export default Text;
