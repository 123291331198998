const regex = {
  nik: /^\d{16}$/, // Nomor Induk Kependudukan
  passport: /^(?!^0+$)[a-zA-Z0-9]{6,9}$/, // Passport
  sim: /^\d{12,16}$/, // Surat Izin Mengemudi
  nikk: /^\d{16}$/, // Nomor Induk Kartu Keluarga
  plat: /^([A-Z]{1,3})(\s|-)*([1-9][0-9]{0,3})(\s|-)*([A-Z]{0,3}|[1-9][0-9]{1,2})$/i, // Plat Nomor Kendaraan
  platMilitary: /^([0-9]{1,5})(\s|-)*([0-9]{2}|[IVX]{1,5})*/, // Plat Nomor Kendaraan Militer
  numberOnly: /^\d+$/,
  alphaNumericOnly: /^[a-zA-Z0-9]+$/,
  phone:
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
  uuid: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
} as const;

export default regex;
