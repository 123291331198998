export const sytles = {
  breakPoints: {
    width: '100%',
    '@sm': {
      maxWidth: 576,
    },
    '@md': {
      maxWidth: 768,
    },
    '@lg': {
      maxWidth: 992,
    },
    '@xl': {
      maxWidth: 1200,
    },
  },
  notFoundImageBreakPoints: {
    width: '100%',
    '@sm': {
      maxWidth: 576,
    },
  },
};
