import classNames from 'classnames';
import * as React from 'react';
import { CSS, css } from 'styles';

export interface StackItemProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?:
    | 'horizontal'
    | 'horizontalReverse'
    | 'vertical'
    | 'verticalReverse';
  alignItems?:
    | 'baseline'
    | 'center'
    | 'end'
    | 'inherit'
    | 'initial'
    | 'start'
    | 'stretch';
  justify?:
    | 'around'
    | 'between'
    | 'center'
    | 'end'
    | 'evenly'
    | 'inherit'
    | 'initial'
    | 'start';
  grow?: boolean;
  wrap?: boolean | 'reverse';
  gap?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl';
  css?: CSS;
}

export interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?:
    | 'horizontal'
    | 'horizontalReverse'
    | 'vertical'
    | 'verticalReverse';
  alignItems?:
    | 'baseline'
    | 'center'
    | 'end'
    | 'inherit'
    | 'initial'
    | 'start'
    | 'stretch';
  justify?:
    | 'around'
    | 'between'
    | 'center'
    | 'end'
    | 'evenly'
    | 'inherit'
    | 'initial'
    | 'start';
  grow?: boolean;
  wrap?: boolean | 'reverse';
  gap?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl';
  Item?: React.FunctionComponent<StackItemProps>;
  rootCss?: CSS;
  itemCss?: CSS;
}

export const StackItem = React.forwardRef<HTMLDivElement, StackItemProps>(
  (props, ref) => {
    const {
      gap,
      direction = 'vertical',
      wrap,
      justify,
      alignItems,
      className,
      grow,
      css,
      ...rest
    } = props;
    return (
      <div
        {...rest}
        className={classNames(
          stackStyle.innerStack({
            direction,
            grow,
            justify,
            gap,
            wrap,
            alignItems,
            css,
          }),
          className,
        )}
        ref={ref}
      />
    );
  },
);

StackItem.displayName = 'Stack.Item';

export const StackRoot = React.forwardRef<HTMLDivElement, StackProps>(
  (props, ref) => {
    const {
      className,
      gap,
      wrap,
      direction = 'vertical',
      grow,
      justify,
      alignItems,
      children,
      rootCss,
      itemCss,
      ...rest
    } = props;

    return (
      <div
        className={classNames(
          stackStyle.outerStack({
            direction,
            grow,
            justify,
            gap,
            wrap,
            alignItems,
            css: rootCss,
          }),
          className,
        )}
        {...rest}
        ref={ref}
      >
        {React.Children.map(children, (child, index) => {
          const childType: any | undefined | null = child?.valueOf();
          if (
            child &&
            typeof childType === 'object' &&
            childType.type.displayName === 'Stack.Item'
          ) {
            return (
              <React.Fragment key={index}>
                <StackItem
                  //@ts-ignore
                  {...child.props}
                  gap={gap}
                />
              </React.Fragment>
            );
          }

          return (
            <StackItem
              //@ts-ignore
              grow={child && child.props.grow}
              //@ts-ignore
              className={child && child.props.className}
              gap={gap}
              key={index}
              css={itemCss}
            >
              {child}
            </StackItem>
          );
        })}
      </div>
    );
  },
);

StackRoot.displayName = 'Stack';

const Stack = Object.assign(StackRoot, { Item: StackItem });

export default Stack;

const gapvars = {
  xxs: 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 20,
  xl: 24,
  xxl: 28,
  xxxl: 32,
  xxxxl: 36,
};

const stackStyle = {
  outerStack: css({
    display: 'flex',
    variants: {
      direction: {
        horizontal: {
          flexDirection: 'row',
        },
        vertical: {
          flexDirection: 'column',
        },
        horizontalReverse: {
          flexDirection: 'row-reverse',
        },
        verticalReverse: {
          flexDirection: 'column-reverse',
        },
      },
      alignItems: {
        baseline: {
          alignItems: 'baseline',
        },
        center: {
          alignItems: 'center',
        },
        end: {
          alignItems: 'flex-end',
        },
        inherit: {
          alignItems: 'inherit',
        },
        initial: {
          alignItems: 'initial',
        },
        start: {
          alignItems: 'flex-start',
        },
        stretch: {
          alignItems: 'stretch',
        },
      },
      justify: {
        around: {
          justifyContent: 'space-around',
        },
        between: {
          justifyContent: 'space-between',
        },
        center: {
          justifyContent: 'center',
        },
        end: {
          justifyContent: 'flex-end',
        },
        evenly: {
          justifyContent: 'space-evenly',
        },
        inherit: {
          justifyContent: 'inherit',
        },
        initial: {
          justifyContent: 'initial',
        },
        start: {
          justifyContent: 'flex-start',
        },
      },
      grow: {
        true: {
          flexGrow: 1,
        },
      },
      wrap: {
        true: {
          flexWrap: 'wrap',
        },
        reverse: {
          flexWrap: 'wrap-reverse',
        },
      },
      gap: {
        xxs: {
          margin: -1 * (gapvars['xxs'] / 2),
        },
        xs: {
          margin: -1 * (gapvars['xs'] / 2),
        },
        sm: {
          margin: -1 * (gapvars['sm'] / 2),
        },
        md: {
          margin: -1 * (gapvars['md'] / 2),
        },
        lg: {
          margin: -1 * (gapvars['lg'] / 2),
        },
        xl: {
          margin: -1 * (gapvars['xl'] / 2),
        },
        xxl: {
          margin: -1 * (gapvars['xxl'] / 2),
        },
        xxxl: {
          margin: -1 * (gapvars['xxxl'] / 2),
        },
        xxxxl: {
          margin: -1 * (gapvars['xxxxl'] / 2),
        },
      },
    },
  }),
  innerStack: css({
    display: 'flex',
    boxSizing: 'border-box',
    variants: {
      direction: {
        horizontal: {
          flexDirection: 'row',
        },
        vertical: {
          flexDirection: 'column',
        },
        horizontalReverse: {
          flexDirection: 'row-reverse',
        },
        verticalReverse: {
          flexDirection: 'column-reverse',
        },
      },
      alignItems: {
        baseline: {
          alignItems: 'baseline',
        },
        center: {
          alignItems: 'center',
        },
        end: {
          alignItems: 'flex-end',
        },
        inherit: {
          alignItems: 'inherit',
        },
        initial: {
          alignItems: 'initial',
        },
        start: {
          alignItems: 'flex-start',
        },
        stretch: {
          alignItems: 'stretch',
        },
      },
      justify: {
        around: {
          justifyContent: 'space-around',
        },
        between: {
          justifyContent: 'space-between',
        },
        center: {
          justifyContent: 'center',
        },
        end: {
          justifyContent: 'flex-end',
        },
        evenly: {
          justifyContent: 'space-evenly',
        },
        inherit: {
          justifyContent: 'inherit',
        },
        initial: {
          justifyContent: 'initial',
        },
        start: {
          justifyContent: 'flex-start',
        },
      },
      wrap: {
        true: {
          flexWrap: 'wrap',
        },
        reverse: {
          flexWrap: 'wrap-reverse',
        },
      },
      grow: {
        true: {
          flexGrow: 1,
        },
      },
      gap: {
        xxs: {
          margin: gapvars['xxs'] / 2,
        },
        xs: {
          margin: gapvars['xs'] / 2,
        },
        sm: {
          margin: gapvars['sm'] / 2,
        },
        md: {
          margin: gapvars['md'] / 2,
        },
        lg: {
          margin: gapvars['lg'] / 2,
        },
        xl: {
          margin: gapvars['xl'] / 2,
        },
        xxl: {
          margin: gapvars['xxl'] / 2,
        },
        xxxl: {
          margin: gapvars['xxxl'] / 2,
        },
        xxxxl: {
          margin: gapvars['xxxxl'] / 2,
        },
      },
    },
  }),
};
