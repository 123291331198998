/**
 * Auto-generated static file paths.
 * Do not edit manually.
 */

const staticFilePaths = {
  androidChrome192x192PNG: '/android-chrome-192x192.png',
  androidChrome512x512PNG: '/android-chrome-512x512.png',
  appleTouchIconPNG: '/apple-touch-icon.png',
  WJLLogoDefaultPNG: '/assets/WJL-logo-default.png',
  WJLLogoDefaultSVG: '/assets/WJL-logo-default.svg',
  WJLLogoDefaultWEBP: '/assets/WJL-logo-default.webp',
  WJLLogoLogoonlyWhiteSVG: '/assets/WJL-logo-logoonly-white.svg',
  WJLLogoLongWhiteSVG: '/assets/WJL-logo-long-white.svg',
  WJLLogoLongSVG: '/assets/WJL-logo-long.svg',
  WJLLogoOnlySVG: '/assets/WJL-logo-only.svg',
  alfamartLogoWEBP: '/assets/alfamart-logo.webp',
  authBgWEBP: '/assets/auth-bg.webp',
  bniLogoWEBP: '/assets/bni-logo.webp',
  briLogoWEBP: '/assets/bri-logo.webp',
  chairFacilityWEBP: '/assets/chair-facility.webp',
  clarityEmailSolidSVG: '/assets/clarity_email-solid.svg',
  clickSVG: '/assets/click.svg',
  facilitatedEnWEBP: '/assets/facilitated-en.webp',
  facilitatedIdWEBP: '/assets/facilitated-id.webp',
  facilitiesWEBP: '/assets/facilities.webp',
  homeAboutUsWEBP: '/assets/home-about-us.webp',
  homeAboutUsEnhancedWEBP: '/assets/home-about-us_enhanced.webp',
  indomaretLogoWEBP: '/assets/indomaret-logo.webp',
  landscapeLoadingGIF: '/assets/landscape-loading.gif',
  loadingGIF: '/assets/loading.gif',
  mandiriLogoWEBP: '/assets/mandiri-logo.webp',
  ourFleet00004WEBP: '/assets/our-fleet-00004.webp',
  paymentSVG: '/assets/payment.svg',
  permataLogoWEBP: '/assets/permata-logo.webp',
  danamonLogoWEBP: '/assets/danamon-logo.webp',
  policy2WEBP: '/assets/policy-2.webp',
  policy3WEBP: '/assets/policy-3.webp',
  policy41WEBP: '/assets/policy-4-1.webp',
  policyWEBP: '/assets/policy.webp',
  refundSVG: '/assets/refund.svg',
  secureSVG: '/assets/secure.svg',
  termAndConditionBgWEBP: '/assets/term-and-condition-bg.webp',
  transactionSecurityWEBP: '/assets/transaction-security.webp',
  wiraBerlianWEBP: '/assets/wira-berlian.webp',
  wiraFastWEBP: '/assets/wira-fast.webp',
  wiraGloryWEBP: '/assets/wira-glory.webp',
  wiraKencanaWEBP: '/assets/wira-kencana.webp',
  wiraMutiaraWEBP: '/assets/wira-mutiara.webp',
  wiraNauliWEBP: '/assets/wira-nauli.webp',
  wiraOnoHinaWEBP: '/assets/wira-ono-hina.webp',
  wiraSamaeriWEBP: '/assets/wira-samaeri.webp',
  wiraVictoriaWEBP: '/assets/wira-victoria.webp',
  favicon16x16PNG: '/favicon-16x16.png',
  favicon32x32PNG: '/favicon-32x32.png',
  faviconICO: '/favicon.ico',
  icon512MaskablePNG: '/icon512_maskable.png',
  icon512RoundedPNG: '/icon512_rounded.png',
  manifestJSON: '/manifest.json',
  vercelSVG: '/vercel.svg',
};

export default staticFilePaths;
