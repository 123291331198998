import { DateTimeModel } from 'api-hooks/booking/model';
import * as DateFNS from 'date-fns';
import { enUS, id } from 'date-fns/locale';
import * as DateFNSwithTZ from 'date-fns-timezone';

let locale = enUS;
export function updateDateLocale(lang) {
  switch (lang) {
    case 'id':
      locale = id;
      break;
    case 'en':
    default:
      locale = enUS;
      break;
  }
}

export function parseISO(dateString: string | Date): Date {
  if (dateString instanceof Date) {
    return dateString;
  }
  return DateFNS.parseISO(dateString);
}

export function parseFromTimeZone(dateString: string, timeZone: string) {
  return DateFNSwithTZ.parseFromTimeZone(dateString, { timeZone });
}

export function format(date: Date | string, dateFormat: string): string {
  if (typeof date === 'string') {
    date = parseISO(date);
  }
  return DateFNS.format(date, dateFormat, {
    locale,
  });
}

export function distanceToNow(date: Date | string, locale = 'en'): string {
  if (typeof date === 'string') {
    date = parseISO(date);
  }
  return DateFNS.formatDistanceToNow(date, {
    locale: locale === 'en' ? enUS : id,
    addSuffix: true,
  });
}

export const differenceInDays = DateFNS.differenceInDays;

export function pad(n: number) {
  return n < 10 ? '0' + n : n;
}

export function convertTime(minute: number) {
  let hour: string | number = Math.floor(minute / 60);
  hour = pad(hour);
  let minutes: string | number = minute % 60;
  minutes = pad(minutes);

  return hour.toString() + ':' + minutes.toString();
}

export function getFormatTimezone(date: string | Date, targetTz: number) {
  const initialDate = typeof date === 'string' ? new Date(date) : date;
  const targetTimezone = targetTz;
  const tzDifference = targetTimezone * 60 + initialDate.getTimezoneOffset();
  const offsetTime = new Date(initialDate.getTime() + tzDifference * 60 * 1000);
  return {
    offsetTime,
    targetTimezone:
      targetTimezone >= 0 ? `GMT+${targetTimezone}` : `GMT-${targetTimezone}`,
  };
}

export function birthdayAge(date: string | Date) {
  const initialDate = typeof date === 'string' ? new Date(date) : date;
  const currentDate = new Date();
  const gapYear = currentDate.getFullYear() - initialDate.getFullYear();
  if (currentDate.getMonth() >= initialDate.getMonth()) {
    return gapYear;
  }

  return gapYear - 1;
}

export function utcToLocalDate(date: string | Date) {
  let current = new Date(date);
  const currentOffset = current.getTimezoneOffset();
  current = DateFNS.addMinutes(current, currentOffset);
  return current;
}
/**
 * Convert date to WIB, WITA, or WIT timezone.
 * WIB: UTC+7
 * WITA: UTC+8
 * WIT: UTC+9
 * @param date string or Date object and must utc
 * @param tz WIB, WITA, or WIT
 * @returns Date object in converted timezone
 */
export function wjlTimezoneConversion(date: string | Date, tz: string) {
  const timezone = tz.toUpperCase();
  let targetTimezone = -420;
  if (timezone === 'WIB') {
    targetTimezone = -420;
  } else if (timezone === 'WITA') {
    targetTimezone = -480;
  } else if (timezone === 'WIT') {
    targetTimezone = -540;
  }

  targetTimezone = targetTimezone - new Date().getTimezoneOffset();

  const time = new Date(date).getTime() - targetTimezone * 60 * 1000;
  const result = new Date(time);
  return result;
}

/**
 * Converts a DateTimeModel object to a JavaScript Date object, adjusting for
 * specific Indonesian timezones (WIB, WITA, WIT) and optionally applying the
 * local timezone offset.
 *
 * @param date - An object containing `date` and `time` properties to be
 *               converted. The `time` property should indicate the timezone
 *               with WIB, WITA, or WIT.
 * @param addOffset - A boolean indicating whether to adjust the time to the
 *                    local timezone offset. Defaults to true.
 * @returns A Date object representing the converted date and time.
 */

export function wjlTimeConversion(date: DateTimeModel, addOffset = true) {
  const dateAt = [date.date, date.time]
    .join('T')
    .replace(' WIB', '+07:00')
    .replace(' WITA', '+08:00')
    .replace(' WIT', '+09:00');

  if (addOffset) {
    const currentOffset = new Date().getTimezoneOffset();
    const time = date.time;
    let targetOffset = -420;
    if (time?.includes('WIB')) {
      targetOffset = -420;
    } else if (time?.includes('WITA')) {
      targetOffset = -480;
    } else if (time?.includes('WIT')) {
      targetOffset = -540;
    }
    targetOffset = currentOffset - targetOffset;
    const result = DateFNS.addHours(new Date(dateAt), targetOffset / 60);
    return result;
  }

  return new Date(dateAt);
}
